<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="200px"
          label-position="right"
          size="mini"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="巡检次数" prop="patrolTimes">
            <el-input v-model="form.patrolTimes" disabled></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="content">
            <el-input
              type="textarea"
              rows="5"
              v-model="form.content"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item
                :title="'第' + item.patrolTimes + '次'"
                :name="item.patrolTimes"
                v-for="item in estateList"
                :key="item.patrolTimes"
              >
                <el-card
                  class="box-card"
                  v-for="subItem in item.questionList"
                  :key="subItem.guid"
                >
                  <p>{{ subItem.title }}</p>
                  <p>{{ subItem.typeStr }}</p>
                  <el-input
                    type="textarea"
                    v-model="subItem.remark"
                    disabled
                  ></el-input>
                  <div class="demo-image">
                    <div
                      class="block"
                      v-for="imgUrl in subItem.imageUrlList"
                      :key="imgUrl"
                    >
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="imgUrl"
                        :preview-src-list="subItem.imageUrlList"
                        :fit="'cover'"
                      ></el-image>
                    </div>
                  </div>
                  <div style="margin-top: 10px">
                    <el-button
                      type="primary"
                      @click="
                        showDialog(
                          '提出复议',
                          '/reconsider-history/save',
                          subItem.submitDetailGuid
                        )
                      "
                      >复议</el-button
                    >
                    <el-popover
                      placement="top"
                      width="1100"
                      trigger="click"
                      v-if="subItem.reconsiderHistoryList.length > 0"
                      style="margin-left: 20px"
                    >
                      <el-table :data="subItem.reconsiderHistoryList">
                        <el-table-column
                          width="200"
                          property="createTime"
                          label="复议时间"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="reason"
                          label="复议原因"
                        ></el-table-column>
                        <el-table-column
                          width="100"
                          property="statusStr"
                          label="状态"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="updateTime"
                          label="回复时间"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="remark"
                          label="回复内容"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="imageUrlList"
                          label="图片"
                        >
                          <!--插入图片链接的代码-->
                          <template slot-scope="scope">
                            <el-image
                              :z-index="9999"
                              :src="imageItem"
                              v-for="imageItem in scope.row.imageUrlList"
                              :preview-src-list="scope.row.imageUrlList"
                              :key="imageItem"
                              alt=""
                              style="width: 50px; height: 50px"
                            ></el-image>
                          </template>
                        </el-table-column>
                      </el-table>
                      <el-button slot="reference" type="primary"
                        >复议记录</el-button
                      >
                    </el-popover>
                    <el-button
                      type="primary"
                      style="margin-left: 20px"
                      @click="
                        showDialog(
                          '提出整改',
                          '/rectify-history/save',
                          subItem.submitDetailGuid
                        )
                      "
                      >整改</el-button
                    >
                    <el-popover
                      placement="top"
                      width="1100"
                      trigger="click"
                      border="true"
                      style="margin-left: 20px"
                      v-if="subItem.rectifyHistoryList.length > 0"
                    >
                      <el-table :data="subItem.rectifyHistoryList">
                        <el-table-column
                          width="200"
                          property="createTime"
                          label="整改时间"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="reason"
                          label="整改内容"
                        ></el-table-column>
                        <el-table-column
                          width="100"
                          property="statusStr"
                          label="状态"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="updateTime"
                          label="回复时间"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="remark"
                          label="回复内容"
                        ></el-table-column>
                        <el-table-column
                          width="200"
                          property="imageUrlList"
                          label="图片"
                        >
                          <!--插入图片链接的代码-->
                          <template slot-scope="scope">
                            <el-image
                              :z-index="9999"
                              :src="imageItem"
                              v-for="imageItem in scope.row.imageUrlList"
                              :preview-src-list="scope.row.imageUrlList"
                              :key="imageItem"
                              alt=""
                              style="width: 50px; height: 50px"
                            ></el-image>
                          </template>
                        </el-table-column>
                      </el-table>
                      <el-button slot="reference" type="primary"
                        >整改记录</el-button
                      >
                    </el-popover>
                  </div>
                </el-card>
              </el-collapse-item>
            </el-collapse>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog :title="titleText" :visible.sync="dialogVisible" width="30%">
      <el-input type="textarea" v-model="comment" rows="5"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card-question {
  width: 100%;
}
.demo-image {
  overflow: hidden;
}
.demo-image > div {
  float: left;
  margin: 5px 5px 0 0;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
}
</style>
<script>
export default {
  data() {
    return {
      image_: require("../../assets/icons-plus.png"),
      form: {},
      userInfo: {},
      allDeptPosition: [],
      rules: {},
      typeList: [],
      estateList: [],
      activeName: 1,
      dialogVisible: false,
      titleText: "",
      submitUrl: "",
      comment: "",
      surveySubmitDetailGuid: "",
    };
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getSurveyDetail(self.$route.query.guid || null);
    self.getPatrolType();
    self.getEstateList();
  },
  methods: {
    getSurveyDetail(guid) {
      let self = this;
      if (guid) {
        self.$axios
          .get("/patrol-survey/get/" + guid, {
            headers: { token: self.userInfo.token },
          })
          .then(function (response) {
            self.form = response.data.data;
          })
          .catch(function (error) {
            self.$message({
              message: error.response.data.message,
              type: "warning",
            });
          });
      } else {
        self.form = {
          name: "",
        };
      }
    },
    showDialog(titleText, url, guid) {
      console.log(guid);
      let self = this;
      self.titleText = titleText;
      self.submitUrl = url;
      self.dialogVisible = true;
      self.surveySubmitDetailGuid = guid;
    },
    getPatrolType() {
      let self = this;
      self.$axios
        .get("/patrol-type/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.typeList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "巡检类型获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getEstateList() {
      let self = this;
      let communityGuid = self.$route.query.communityGuid || "";
      let surveyGuid = self.$route.query.guid || "";
      self.$axios
        .get(
          "/patrol-question/list/estate?surveyGuid=" +
            surveyGuid +
            "&communityGuid=" +
            communityGuid,
          {
            headers: { token: self.userInfo.token },
          }
        )
        .then(function (response) {
          self.estateList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
    doSubmit() {
      let self = this;
      console.log(self.surveySubmitDetailGuid);
      let data = {
        surveySubmitDetailGuid: self.surveySubmitDetailGuid,
        reason: this.comment,
      };
      self.$axios
        .post(self.submitUrl, JSON.stringify(data), {
          headers: {
            "content-type": "application/json",
            token: self.userInfo.token,
          },
        })
        .then(function () {
          self.$message({
            message: "操作成功",
            type: "success",
          });
          self.getEstateList();
          self.dialogVisible = false;
        })
        .catch(function (error) {
          self.$message({
            message: error.response.data.message,
            type: "warning",
          });
        });
    },
  },
};
</script>

<style>
</style>